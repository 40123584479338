define("discourse/plugins/discourse-singfuse/discourse/components/blocks/photo", ["exports", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Photo = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="photo">
      <div class="heading flex">
        <h3>{{icon @block.icon}} {{@block.name}}</h3>
      </div>
      <a href="{{@block.data.url}}">
        <img src="{{@block.data.url}}" />
      </a>
    </div>
  
  */
  {
    "id": "hNp0eUHx",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"photo\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"heading flex\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[1,\" \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,3],[15,6,[29,[[30,1,[\"data\",\"url\"]]]]],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[29,[[30,1,[\"data\",\"url\"]]]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@block\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/photo.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = Photo;
});