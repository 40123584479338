define("discourse/plugins/discourse-singfuse/discourse/components/block-list", ["exports", "@ember/helper", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/plugins/discourse-singfuse/discourse/components/blocks/fact", "discourse/plugins/discourse-singfuse/discourse/components/blocks/topic-list", "discourse/plugins/discourse-singfuse/discourse/components/blocks/photo", "discourse/plugins/discourse-singfuse/discourse/components/blocks/map", "discourse/plugins/discourse-singfuse/discourse/components/blocks/category-tags", "discourse/plugins/discourse-singfuse/discourse/components/blocks/weather", "discourse/plugins/discourse-singfuse/discourse/components/blocks/intro", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _component, _didInsert, _service, _fact, _topicList, _photo, _map, _categoryTags, _weather, _intro, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPONENTS = {
    fact: _fact.default,
    topic_list: _topicList.default,
    photo: _photo.default,
    map: _map.default,
    category_tags: _categoryTags.default,
    weather: _weather.default,
    intro: _intro.default
  };
  class BlockList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "blocks", [_service.service]))();
    #blocks = (() => (dt7948.i(this, "blocks"), void 0))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="block-list" {{didInsert this.blocks.get}}>
          {{#each this.blocks.items as |block|}}
            <div class="block {{block.type}}" style="grid-row: span {{block.row_span}}; grid-column: span {{block.col_span}}">
              {{#let (get COMPONENTS block.type) as |Component|}}
                <Component @block={{block}} />
              {{/let}}
            </div>
          {{/each}}
        </div>
      
    */
    {
      "id": "iuMthNMG",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"block-list\"],[4,[32,0],[[30,0,[\"blocks\",\"get\"]]],null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"blocks\",\"items\"]]],null]],null],null,[[[1,\"        \"],[10,0],[15,0,[29,[\"block \",[30,1,[\"type\"]]]]],[15,5,[29,[\"grid-row: span \",[30,1,[\"row_span\"]],\"; grid-column: span \",[30,1,[\"col_span\"]]]]],[12],[1,\"\\n\"],[44,[[28,[32,1],[[32,2],[30,1,[\"type\"]]],null]],[[[1,\"            \"],[8,[30,2],null,[[\"@block\"],[[30,1]]],null],[1,\"\\n\"]],[2]]],[1,\"        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"block\",\"Component\"],false,[\"each\",\"-track-array\",\"let\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/block-list.js",
      "scope": () => [_didInsert.default, _helper.get, COMPONENTS],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BlockList;
});