define("discourse/plugins/discourse-singfuse/discourse/components/blocks/intro", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/emoji", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _emoji, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Intro extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="heading flex">
          <h3>{{emoji @block.icon}} {{@block.name}} <i>Community</i></h3>
        </div>
        <div class="content">
          <a class="btn btn-primary join" href="/signup">Create an account</a>
          <a href="/login" class="login">Sign In</a>
        </div>
      
    */
    {
      "id": "sbR+W8u+",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"heading flex\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[1,\" \"],[1,[30,1,[\"name\"]]],[1,\" \"],[10,\"i\"],[12],[1,\"Community\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"btn btn-primary join\"],[14,6,\"/signup\"],[12],[1,\"Create an account\"],[13],[1,\"\\n      \"],[10,3],[14,6,\"/login\"],[14,0,\"login\"],[12],[1,\"Sign In\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@block\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/intro.js",
      "scope": () => [_emoji.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Intro;
});