define("discourse/plugins/discourse-singfuse/discourse/components/blocks/category-tags", ["exports", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CategoryTags = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <a href="{{@block.url}}" class="heading flex">
        <h3>{{icon @block.icon}} {{@block.name}}</h3>
        {{icon "arrow-right"}}
      </a>
      <div class="content">
        <div class="grid">
          {{#each @block.data as |tag|}}
            <a href="/tags/c/tourism/tourist-attraction/6/{{tag.name}}" class="tag">{{tag.name}}</a>
          {{/each}}
        </div>
      </div>
  
  */
  {
    "id": "Y8/na41y",
    "block": "[[[1,\"\\n    \"],[10,3],[15,6,[29,[[30,1,[\"url\"]]]]],[14,0,\"heading flex\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[1,\" \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[1,[28,[32,0],[\"arrow-right\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"grid\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"          \"],[10,3],[15,6,[29,[\"/tags/c/tourism/tourist-attraction/6/\",[30,2,[\"name\"]]]]],[14,0,\"tag\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[\"@block\",\"tag\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/category-tags.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = CategoryTags;
});