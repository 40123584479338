define("discourse/plugins/discourse-singfuse/discourse/components/homepage", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-singfuse/discourse/components/block-list", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _blockList, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Homepage extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <BlockList />
      
    */
    {
      "id": "eu6SWpRx",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/homepage.js",
      "scope": () => [_blockList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Homepage;
});